import { catalogApiService } from "@/api/services";
import { ReactNode } from "react";
import { useQuery } from "react-query";
import { ProductList } from "@/api/services/catalog/models/Product.model";

type RenderProductFetcherProps = {
    items: ProductList[];
    // items: ProductDetail[];
    // items: Product[];
    count: number;
};

export type ProductFetcherProps = {
    idsList: number[];
    children: ((props: RenderProductFetcherProps) => ReactNode) | ReactNode;
};

const ProductFetcher = ({ idsList, children }: ProductFetcherProps) => {
    const { data } = useQuery(
        ["products", idsList],
        () =>
            catalogApiService.getProducts({
                id__in: idsList.join(","),
            }),
        { enabled: idsList.length > 0 }
    );

    const props: RenderProductFetcherProps = {
        items: data?.results || [],
        // items: data?.items || [],
        count: data?.count || 0,
    };

    return typeof children === "function" ? children(props) : children;
};

export default ProductFetcher;
