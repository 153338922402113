import { arrayToSubArrays } from "@/modules/utils";
import { Entity } from "../models";
import { BrandSchema } from "../schemas";
import EntityModel from "./EntityModel";

interface Guarantee {
    title: string;
    text: string;
}

class BrandEntity extends EntityModel<BrandSchema> {
    readonly title: string;
    readonly image: string;
    readonly background: string;
    readonly html: string;
    readonly catalogUrl: string;
    readonly newsIds: number[];
    readonly techIds: number[];
    readonly reviewsIds: number[];
    readonly url: string;
    readonly otherBrandsIds: number[];
    readonly guarantees: Guarantee[];
    readonly display_order: string;

    constructor(entity: Entity<BrandSchema>) {
        super(entity);
        this.title = entity.getFirstAttribute("title")?.getFirstValue()?.value || "";
        this.image = entity?.getFirstAttribute("image")?.getFirstValue()?.display || "";
        this.background = entity?.getFirstAttribute("background")?.getFirstValue()?.display || "";
        this.html = entity?.getFirstAttribute("html")?.getFirstValue()?.value || "";
        this.catalogUrl = entity?.getFirstAttribute("catalog_url")?.getFirstValue()?.value || "";
        this.newsIds = entity?.getFirstAttribute("news")?.values.map((el) => el.entityId) || [];
        this.techIds = entity?.getFirstAttribute("technologies")?.values.map((el) => el.entityId) || [];
        this.reviewsIds = entity?.getFirstAttribute("video_reviews")?.values.map((el) => el.entityId) || [];
        this.url = entity?.getFirstAttribute("url")?.getFirstValue()?.value || "";
        this.otherBrandsIds = entity?.getFirstAttribute("other_brands")?.values.map((el) => el.entityId) || [];
        this.guarantees = arrayToSubArrays(
            entity.getFirstAttribute("guarantees")?.values.filter((el) => !!el.value) || [],
            2
        ).map((el) => ({ title: el[0]?.value ?? "", text: el[1]?.value ?? "" }));
        this.display_order = entity.getFirstAttribute("display_order")?.getFirstValue()?.value ?? "";
    }
}

export default BrandEntity;
