import React from "react";
import Layout from "@/components/Common/Layout";
import Product from "@/components/Product";
import ProductDetail from "@/components/ProductDetail";
import ProductView from "@/views/Product";
import { WithPageProps } from "@/modules/pages";
import { useLocation } from "react-router-dom";
import ProductRecommendations from "@/components/ProductRecommendations";
import { ProductStructuredDataModel } from "@/api/services/common/models/ProductStructuredData.model";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import catalogApiService from "@/api/services/catalog";
import { Config } from "@/modules/constants";

function removeHtmlTags(htmlString) {
    if (!htmlString) return ""; // Если строка пустая или null
    return htmlString
        .replace(/<[^>]*>/g, "")
        .replace(/\s+/g, " ")
        .trim();
}

const ProductPage: React.FunctionComponent<WithPageProps> = (props: WithPageProps) => {
    const { pathname } = useLocation();
    // const location = pathname;
    // const absoluteUrl = `${Config.SITE_NAME}${pathname}`;
    const baseUrl = Config.SITE_NAME.startsWith("http") ? Config.SITE_NAME : `https://${Config.SITE_NAME}`;

    const absoluteUrl = `${baseUrl}${pathname}`;

    const commonProps = { id: props.context.id };
    const product = props.context;
    const productId = props.context.id;

    const { data: productWithSKU, isFetched } = useQuery(
        ["product", productId],
        () => catalogApiService.getProduct(productId),
        { enabled: !!productId }
    );

    const totalQuantity = productWithSKU?.skus
        ? productWithSKU.skus.reduce((sum, sku) => sum + (sku.quantity || 0), 0)
        : 0;
    const availability = totalQuantity > 0 ? "https://schema.org/InStock" : "https://schema.org/OutOfStock";

    const descriptionWithHtml = product?.attr.html?.value || "";
    const cleanedDescription = removeHtmlTags(descriptionWithHtml);
    props.context.seoDescription = cleanedDescription;

    const StructuredDataModel: ProductStructuredDataModel = {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: product?.title,
        // aggregateRating: {
        //     "@type": "AggregateRating",
        //     reviewCount: String(product?.review?.count || 0),
        //     ratingValue: String(product?.review?.avg || 0),
        // },
        brand: {
            "@type": "Brand",
            name: product?.attr.brand?.value || "",
        },
        // description: product?.attr.html?.value || "",
        description: cleanedDescription,
        // sku: currentSku?.attr?.sku?.value || "",
        // image: product?.attr?.images?.value || "",
        image: productWithSKU?.image || "",
        review: {
            "@type": "Review",
            reviewRating: {
                "@type": "Rating",
                // ratingValue: reviews?.results.length ? reviews?.results[0].rates.toString() : "0",
                bestRating: "5",
            },
            author: {
                "@type": "Person",
                name: "День сурка",
            },
        },
        offers: {
            "@type": "Offer",
            price: String(product?.price),
            priceCurrency: "RUB",
            availability: availability,
            // availability: product?.skus.length ? "https://schema.org/InStock" : "https://schema.org/OutOfStock",
            // url: location,
            url: absoluteUrl,
        },
    };
    return (
        <Layout wrap {...props.context}>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(StructuredDataModel)}</script>
            </Helmet>
            <ProductView>
                <Product {...commonProps} />
                <ProductDetail {...commonProps} />
            </ProductView>
            <ProductRecommendations {...commonProps} />
        </Layout>
    );
};

export default ProductPage;
