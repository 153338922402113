import BaseApiService from "@/api/base";
import { CoreQueryParams } from "@/api/types";
import { Config } from "@/modules/constants";
import { Entity, EntityType, PaginatedQueryResult, TypeSchemaAttribute, ValueTypeBind } from "./models";
import { ProductModel } from "@/api/services/common/models/Product.model";
import { ClassConstructor } from "class-transformer";
import { EntityModel } from "./entities";
import { ProductDetail } from "@/api/services/catalog/models/Product.model";
import { AvailableSku } from "@/api/services/entities/models/Available.model";

export type EntitiesQueryParams = CoreQueryParams & Partial<{ et_id: number }>;

class EntitiesApiService extends BaseApiService {
    getEntities = async <Schema>(
        params: EntitiesQueryParams = {},
        callerName: string
    ): Promise<PaginatedQueryResult<Entity<Schema>>> => {
        const { data } = await this.http.get("/nsi_entity/entity", {
            params,
        });

        const result = this.plainToClass<PaginatedQueryResult<Entity<Schema>>, unknown>(PaginatedQueryResult, data);
        result.items = this.plainToClassArray<Entity<Schema>>(Entity, result.items);
        return result;
    };

    getTransformedEntities = async <Schema, M extends EntityModel<Schema>>(
        Model: ClassConstructor<M>,
        params: EntitiesQueryParams = {},
        callerName: string
    ): Promise<PaginatedQueryResult<M>> => {
        const { data } = await this.http.get("/nsi_entity/entity", {
            params,
        });

        const result = this.plainToClass<PaginatedQueryResult<M>, unknown>(PaginatedQueryResult, data);
        const entities = this.plainToClassArray<Entity<Schema>>(Entity, result.items);
        result.items = entities.map((entity) => new Model(entity));
        return result;
    };

    getEntity = async <Schema>(entityId: number): Promise<Entity<Schema> | null> => {
        const { data } = await this.http.get("/nsi_entity/entity", {
            params: { ids_list: entityId },
        });

        const entities = this.plainToClassArray<Entity<Schema>>(Entity, data.result.items);
        return this.getFirstItemOrNull(entities);
    };

    getTransformedEntity = async <Schema, M extends EntityModel<Schema>>(
        Model: ClassConstructor<M>,
        entityId: number,
        callerName: string
    ): Promise<M | null> => {
        const { data } = await this.http.get("/nsi_entity/entity", {
            params: { ids_list: entityId },
        });
        const entities = this.plainToClassArray<Entity<Schema>>(Entity, data.result.items);
        const models = entities.map((entity) => new Model(entity));

        return this.getFirstItemOrNull(models);
    };

    getEntityType = async (entityTypeId: number): Promise<EntityType | null> => {
        const { data } = await this.http.get("/nsi_meta/etype", {
            params: { ids_list: entityTypeId },
        });

        const entities = this.plainToClassArray(EntityType, data.result.items);

        return this.getFirstItemOrNull(entities);
    };

    getTypeSchema = async (entityTypeId: number): Promise<TypeSchemaAttribute[]> => {
        const { data } = await this.http.get("/nsi_meta/type_schema", { params: { id: entityTypeId } });

        const result = this.plainToClassArray<TypeSchemaAttribute, unknown>(TypeSchemaAttribute, data.result);

        return result;
    };

    getValueTypeBind = async (valueTypeId: number, callerName: string): Promise<ValueTypeBind | null> => {
        const { data } = await this.http.get("/settings/ns_vtype_bind", {
            params: { type_id: valueTypeId },
        });
        const binds = this.plainToClassArray(ValueTypeBind, data.result.items);

        return this.getFirstItemOrNull(binds);
    };

    getAvailable = async (params): Promise<Custom.Maybe<AvailableSku[][]>> => {
        const { data } = await this.http.get("/store/quantity", { params });
        return data.result.map((item) => this.plainToClassArray(AvailableSku, item));
    };

    getQuantities = async (params): Promise<Custom.Maybe<AvailableSku[]>> => {
        const { data } = await this.http.get("/store/actual_quantity", { params });
        return this.plainToClassArray(AvailableSku, data.result);
    };

    getProduct = async (id: number): Promise<Custom.Maybe<ProductDetail>> => {
        const { data } = await this.http.get(`/catalog/product/${id}/`);
        return this.plainToClassFromExist(new ProductDetail(), data);
    };

    /** wiil be removed later */
    getFileName = async (id: number): Promise<any> => {
        const { data } = await this.http.get("/file_storage/files", {
            params: { id },
        });

        return data;
    };

    getEntitiesByIds = async (
        idsList: number[],
        params: EntitiesQueryParams = {}
    ): Promise<PaginatedQueryResult<ProductModel>> => {
        const { data } = await this.http.get("/nsi_entity/entity", {
            params: { ids_list: idsList.join(","), ...params },
        });

        const result = this.plainToClass<PaginatedQueryResult<ProductModel>, unknown>(PaginatedQueryResult, data);
        const entities = this.plainToClassArray(ProductModel, result.items);
        result.items = entities;

        return result;
    };
}

export default new EntitiesApiService({
    baseURL: Config.CORE_PROXY_URL,
});
