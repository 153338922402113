import ProductView from "@/views/Product";
import ProductCategoryView from "@/views/ProductCategory";
import ProductImagesView from "@/views/ProductGallery";
import useControls from "../Product/useControls";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { catalogApiService, entitiesApiService } from "@/api/services";
import useCart from "@/modules/hooks/useCart";
import { FormHelperText } from "../Core";
import useFavourite from "@/modules/hooks/useFavourite";
import useCompare from "@/modules/hooks/useCompare";
import { BrandSchema, TechnologySchema } from "@/api/services/entities/schemas";
import { BrandEntity, ColorEntity, TechnologyEntity } from "@/api/services/entities/entities";
import { EntityFetcher } from "../Controls";
import { getMinimalDetailPath } from "@/modules/utils";
import { useModal, usePage } from "@/modules/hooks";
import {
    ProductAttr,
    ProductAttrValueType,
    ProductAttrValueTypes,
    Sku,
} from "@/api/services/catalog/models/Product.model";
import { ActionsProps } from "@/views/Product/Actions";

enum SaleAttr {
    COLOR = "color",
    SIZE = "size",
}

export type ProductProps = { id: number };

// Only if ProductAttrType return by key
const arrayUniqueByKey = (array: Sku[], key: keyof ProductAttr) => {
    return [...new Map(array.map((item) => [item.attr[key]?.id, item])).values()];
};
// Only if ProductAttrTypes return by key
const arrayUniqueByKeyArray = (array: Sku[], key: keyof ProductAttr) => {
    return [...new Map(array.map((item) => [item.attr[key]?.id[0], item])).values()];
};

const makeAttrFirst = (arr: string[], attr) => {
    const attrIndex = arr.indexOf(attr);
    const attrPick = arr.splice(attrIndex, 1);
    return [...attrPick, ...arr];
};

const getString = (item: ProductAttrValueType | ProductAttrValueTypes): string => {
    if (!item) return "";
    return Array.isArray(item.value) ? item.value[0] : item.value;
};

const Product: React.FunctionComponent<ProductProps> = ({ id }: ProductProps) => {
    const entityId = id;
    const { page: currentPage } = usePage();
    const config = currentPage?.config;
    const { thumbsSwiper, setThumbsSwiper } = useControls();
    const { addToCart, cartItems, removeUnit, addUnit, cartError } = useCart();
    const { toggleFavorite, isFavorite } = useFavourite();
    const { toggleCompare, isCompare } = useCompare();
    const { handleOpenModal } = useModal();
    const [isChecked, setIsChecked] = useState(true);

    const [currentSku, setCurrentSku] = useState<Sku | null>(null);

    const [pivot, setPivot] = useState<string>();
    const [pivotStack, setPivotStack] = useState<string[]>();
    const [saleAttrTree, setSaleAttrTree] = useState<{
        [key: string]: Array<ProductAttrValueType | ProductAttrValueTypes>;
    }>();
    const [selectSaleAttrs, setSelectSaleAttrs] = useState<{
        [key: string]: ProductAttrValueType | ProductAttrValueTypes;
    }>();
    const { data: product, isFetched } = useQuery(["product", entityId], () => catalogApiService.getProduct(entityId), {
        enabled: !!entityId,
    });

    // const { data: product, isFetched } = useQuery(["product", entityId], () => catalogApiService.getProduct(entityId), {
    //     enabled: !!entityId,
    //     onSuccess: (data) => {
    //         console.log("Product fetched from server:", data); // Логирование данных
    //     },
    // });

    const { data: quantities } = useQuery(
        ["quantities", currentSku],
        // () => entitiesApiService.getQuantities({ sku_list: `{"${currentSku?.attr.sku?.value}"}` }),
        // vma 11-03-2024 чтобы в артикулах проходила запятая - ставим двойные кавычки в СКУ
        () => entitiesApiService.getQuantities({ sku_list: `{"${currentSku?.attr.sku?.value}"}` }),
        {
            enabled: !!currentSku,
        }
    );
    // const { data: quantities } = useQuery(
    //     ["quantities", currentSku],
    //     () => {
    //         const response = entitiesApiService.getQuantities({
    //             sku_list: `{"${currentSku?.attr.sku?.value}"}`,
    //         });
    //         console.log("API response for quantities:", response); // Логируем ответ
    //         return response;
    //     },
    //     {
    //         enabled: !!currentSku,
    //     }
    // );

    const { data: brand } = useQuery(
        ["brand", product],
        () => {
            if (!product) return null;
            return entitiesApiService.getTransformedEntity<BrandSchema, BrandEntity>(
                BrandEntity,
                Number(product?.attr.brand?.id),
                "src/components/Product/index.tsx"
            );
        },
        { enabled: isFetched && !!product }
    );

    const { data: colors } = useQuery(
        ["colors"],
        () => {
            if (!product) {
                return;
            }

            const colorIds = product.skus
                .filter((sku) => Boolean(sku.attr.color))
                .map((sku) => String(sku.attr.color?.id));

            if (!colorIds.length) {
                return;
            }
            return entitiesApiService.getTransformedEntities(
                ColorEntity,
                {
                    et_id: entityId,
                    ids_list: colorIds.join(",") || "",
                },
                "src/components/Product/index.tsx"
            );
        },
        { enabled: !!product }
    );

    const getColorNull = (title: string) => {
        if (colors) {
            const colorInfo = colors.items.filter((item) => title.includes(item.title)).map((item) => item.colorBrand);
            //.map((item) => item.colorForFiltres); //vma 13/02/2024
            return colorInfo.join(" - ");
        }
    };

    const getSkusWithColor = () => {
        if (saleAttrTree && product) {
            const colorsId = saleAttrTree.color.filter((item) => Boolean(item)).map((item) => item.id);
            const allSkus = [...product.skus];
            const filteredSkus: Sku[] = [];
            colorsId.forEach((colorId) => {
                const sku = allSkus.find((sku) => sku.attr.color?.id === colorId);
                sku && filteredSkus.push(sku);
            });
            return filteredSkus;
        }
    };

    const getSkusWithColorAndQuantity = () => {
        if (saleAttrTree && product) {
            // Получаем ID всех цветов из дерева атрибутов продажи
            const colorsId = saleAttrTree.color.filter((item) => Boolean(item)).map((item) => item.id);
            const allSkus = [...product.skus];
            const filteredSkus: Sku[] = [];

            // console.log("Цвета ID:", colorsId);
            // console.log("Все SKU:", allSkus);

            // Проходим по каждому цвету
            colorsId.forEach((colorId) => {
                // Находим все SKU с данным цветом
                const skusForColor = allSkus.filter((sku) => sku.attr.color?.id === colorId);

                // console.log(`Найдены SKU для цвета ID ${colorId}:`, skusForColor);

                // Проходим по каждому SKU и проверяем наличие
                for (const sku of skusForColor) {
                    if (sku.quantity > 0) {
                        filteredSkus.push(sku);
                        // console.log("SKU добавлен в filteredSkus:", sku);
                        break; // Прерываем цикл, если найден SKU с количеством > 0
                    } else {
                        // console.log("SKU не добавлен, количество 0:", sku);
                    }
                }

                // Если ни один SKU с данным цветом не доступен, логируем это
                // if (skusForColor.length === 0) {
                //     console.log(`Нет SKU для цвета ID ${colorId}`);
                // }
            });

            return filteredSkus;
        }
    };

    const getAllSkusWithColorAndQuantity = () => {
        if (saleAttrTree && product) {
            // Получаем список ID цветов, которые есть у товара
            const colorsId = saleAttrTree.color.filter((item) => Boolean(item)).map((item) => item.id);
            const allSkus = [...product.skus]; // Все SKU продукта
            const filteredSkus: Sku[] = [];

            // Проходим по каждому цвету
            colorsId.forEach((colorId) => {
                // Фильтруем все SKU, которые соответствуют текущему цвету и имеют количество больше 0
                const skusForColor = allSkus.filter((sku) => sku.attr.color?.id === colorId && sku.quantity > 0);

                if (skusForColor.length) {
                    filteredSkus.push(...skusForColor); // Добавляем все найденные SKU для этого цвета
                    // console.log(`Найдено ${skusForColor.length} SKU для цвета ID:`, colorId);
                } else {
                    // console.log(`Нет доступных SKU для цвета ID:`, colorId);
                }
            });

            // console.log("Все отфильтрованные SKU с цветами и количеством:", filteredSkus);
            return filteredSkus;
        }
        return [];
    };

    const setSelectColorHandler = (attr: ProductAttrValueType | null) => {
        if (attr) {
            setPivot(SaleAttr.COLOR);
            setSelectSaleAttrs((prev) => ({ ...prev, color: attr }));
        }
    };

    const qtyInCart = cartItems[currentSku?.id || 0]?.quantity || 0;
    const onAddToCart = () => {
        if (currentSku) {
            addToCart({ sku: currentSku.id });
        }
    };
    const onRemoveUnit = () => {
        if (currentSku) {
            removeUnit(currentSku.id);
        }
    };
    const onAddUnit = () => {
        if (currentSku) {
            addUnit(currentSku.id);
        }
    };
    const onToggleFavorite = () => toggleFavorite({ entityId });
    const onCompare = () => toggleCompare({ entityId });

    const availableQuantityForCurrentSku: number = quantities?.[0].quantity ?? 0;

    const actionsProps: ActionsProps = {
        onAddToCart: onAddToCart,
        qtyInCart,
        onRemoveUnit,
        onAddUnit,
        onAddToFavorite: onToggleFavorite,
        inFavourite: entityId ? isFavorite(entityId) : false,
        canAddToCart: Boolean(currentSku?.salePrice) && Boolean(availableQuantityForCurrentSku),
        canAddUnit: qtyInCart < availableQuantityForCurrentSku,
        error: cartError?.unauthorized,
    };

    const actionsTopProps = {
        sku: currentSku?.attr.sku?.value || "",
        onCompare,
        inCompare: entityId ? isCompare({ entityId }) : false,
        ratingCount: product?.review?.count ?? 0,
        ratingAverage: product?.review?.avg ?? 0,
    };

    // useEffect(() => {
    //     if (product && product.skus.length) {
    //         //Выбираем первую sku по атрибутам продажи если есть цвет ставим первым на рендер
    //         let saleAttrs = product.skus[0].saleAttrs;
    //         if (saleAttrs.includes(SaleAttr.COLOR)) {
    //             saleAttrs = makeAttrFirst(saleAttrs, SaleAttr.COLOR);
    //         }
    //         const firstAttr = saleAttrs[0];
    //         saleAttrs.forEach((saleAttr) => {
    //             const skuSaleAttrValue = product.skus[0].attr[saleAttr];
    //             setSelectSaleAttrs((prevSaleAttrs) => ({ ...prevSaleAttrs, [saleAttr]: skuSaleAttrValue }));
    //         });
    //         //Выбираем первый pivot
    //         setPivot(firstAttr);
    //         //определяем стек рендера
    //         setPivotStack(saleAttrs);
    //
    //         //Выбираем уникальные sale_attr для первого елемента pivot stack
    //         const skuSaleAttrValue = product.skus[0].attr[firstAttr];
    //         let skusBySaleAttrs;
    //         if (Array.isArray(skuSaleAttrValue?.id)) {
    //             skusBySaleAttrs = arrayUniqueByKeyArray(product.skus, firstAttr).map((sku) => sku.attr[firstAttr]);
    //         } else {
    //             skusBySaleAttrs = arrayUniqueByKey(product.skus, firstAttr).map((sku) => sku.attr[firstAttr]);
    //         }
    //         setSaleAttrTree((prevState) => ({ ...prevState, [firstAttr]: skusBySaleAttrs }));
    //         setCurrentSku(product.skus[0]);
    //     }
    // }, [product]);

    useEffect(() => {
        if (isChecked && product && selectSaleAttrs && product.skus.length) {
            // Ищем первый SKU с наличием при включённом фильтре
            const availableSku = product.skus.find((sku) => sku.quantity > 0);
            if (availableSku) {
                // Устанавливаем текущий SKU на первый доступный с наличием
                setCurrentSku(availableSku);

                // Обновляем выбранные атрибуты в соответствии с этим SKU
                Object.keys(selectSaleAttrs).forEach((key) => {
                    setSelectSaleAttrs((prevSaleAttrs) => ({
                        ...prevSaleAttrs,
                        [key]: availableSku.attr[key],
                    }));
                });
            }
        }
    }, [isChecked, product]); // Важно, чтобы эффект зависел от `isChecked`

    useEffect(() => {
        if (product && product.skus.length) {
            let firstSku = product.skus[0]; // По умолчанию берем первый SKU

            if (isChecked) {
                // Если включен фильтр "Только в наличии", ищем первый SKU с количеством > 0
                const availableSku = product.skus.find((sku) => sku.quantity > 0);
                if (availableSku) {
                    firstSku = availableSku;
                } else {
                    console.log("Нет доступных SKU с наличием.");
                    return; // Останавливаем выполнение если нет SKU с наличием
                }
                if (currentSku && currentSku.quantity === 0) {
                    // Если выбранный SKU недоступен, переключаем на первый SKU в наличии
                    setCurrentSku(firstSku);
                }
            }

            // Получаем атрибуты продажи первого SKU
            let saleAttrs = firstSku.saleAttrs;

            // Если среди атрибутов есть цвет, ставим его первым
            if (saleAttrs.includes(SaleAttr.COLOR)) {
                saleAttrs = makeAttrFirst(saleAttrs, SaleAttr.COLOR);
            }

            const firstAttr = saleAttrs[0];

            // Устанавливаем значения атрибутов для выбранного SKU
            saleAttrs.forEach((saleAttr) => {
                const skuSaleAttrValue = firstSku.attr[saleAttr];
                setSelectSaleAttrs((prevSaleAttrs) => ({ ...prevSaleAttrs, [saleAttr]: skuSaleAttrValue }));
            });

            // Устанавливаем первый атрибут как pivot
            setPivot(firstAttr);

            // Определяем стек рендера
            setPivotStack(saleAttrs);

            // Выбираем уникальные значения sale_attr для первого элемента pivot stack
            const skuSaleAttrValue = firstSku.attr[firstAttr];
            let skusBySaleAttrs;
            if (Array.isArray(skuSaleAttrValue?.id)) {
                skusBySaleAttrs = arrayUniqueByKeyArray(product.skus, firstAttr).map((sku) => sku.attr[firstAttr]);
            } else {
                skusBySaleAttrs = arrayUniqueByKey(product.skus, firstAttr).map((sku) => sku.attr[firstAttr]);
            }

            // Обновляем дерево атрибутов
            setSaleAttrTree((prevState) => ({ ...prevState, [firstAttr]: skusBySaleAttrs }));

            // Устанавливаем текущий SKU
            setCurrentSku(firstSku);
        }
        // }, [product, isChecked]);
    }, [product]);

    useEffect(() => {
        if (selectSaleAttrs && pivot && pivotStack && product) {
            //отрисовка saleAttrTree
            const pivotIndex = pivotStack.indexOf(pivot);
            const influencedSaleAttrs = [...pivotStack];
            const renderStack =
                pivotIndex === 0 ? influencedSaleAttrs.splice(pivotIndex + 1) : influencedSaleAttrs.splice(pivotIndex);
            let skusWithInfluenced = [...product.skus];
            let tree = { ...saleAttrTree };
            for (let j = 0; j < renderStack.length; j++) {
                for (let i = 0; i < influencedSaleAttrs.length; i++) {
                    skusWithInfluenced = skusWithInfluenced.filter((sku) => {
                        const attrName: string | undefined = influencedSaleAttrs[i];
                        return sku.attr[attrName]?.id === selectSaleAttrs[attrName]?.id;
                    });
                }
                if (skusWithInfluenced.length) {
                    const skuSaleAttrValue = skusWithInfluenced[0].attr[renderStack[j]];
                    let uniqSkusWithInfluenced: Sku[];
                    if (Array.isArray(skuSaleAttrValue?.id)) {
                        uniqSkusWithInfluenced = arrayUniqueByKeyArray(skusWithInfluenced, renderStack[j]);
                    } else {
                        uniqSkusWithInfluenced = arrayUniqueByKey(skusWithInfluenced, renderStack[j]);
                    }
                    const skusWithInfluencedCurrentSaleAttr = uniqSkusWithInfluenced.map(
                        (sku) => sku.attr[renderStack[j]]
                    );

                    tree = { ...tree, [renderStack[j]]: skusWithInfluencedCurrentSaleAttr };
                }
                if (!influencedSaleAttrs.includes(renderStack[j])) {
                    influencedSaleAttrs.push(renderStack[j]);
                }
            }
            setSaleAttrTree({ ...tree });
            //выбор selectSaleAttrs
        }
    }, [selectSaleAttrs]);

    useEffect(() => {
        if (saleAttrTree && selectSaleAttrs && product) {
            //Выбираем активный елемент если в дереве такого нет берем 0
            Object.keys(saleAttrTree).forEach((key) => {
                if (!selectSaleAttrs[key]) return;

                if (
                    !saleAttrTree[key].some((item) => {
                        if (!item) {
                            return true;
                        }

                        return Array.isArray(item.id)
                            ? item.id?.[0] === selectSaleAttrs[key].id?.[0]
                            : item.id === selectSaleAttrs[key].id;
                    })
                ) {
                    setSelectSaleAttrs((prev) => ({ ...prev, [key]: saleAttrTree[key][0] }));
                }
            });
            //Находим sku по заданным атрибутам
            let skus = [...product.skus];

            // Если чекбокс активен (isChecked === true), фильтруем по количеству больше 0
            // if (isChecked) {
            //     skus = skus.filter((sku) => sku.quantity > 0);
            // }

            Object.keys(selectSaleAttrs).forEach((key) => {
                if (!selectSaleAttrs[key]) return;
                skus = skus.filter((sku) => {
                    if (!sku.attr[key]?.id) return false;
                    return Array.isArray(sku.attr[key].id)
                        ? sku.attr[key].id[0] === selectSaleAttrs[key].id[0]
                        : sku.attr[key].id === selectSaleAttrs[key].id;
                });
            });

            skus[0] && setCurrentSku(skus[0]);
        }
        // }, [saleAttrTree, isChecked]);
    }, [saleAttrTree]);

    return (
        <>
            <ProductView.Row>
                <ProductView.Col position="left">
                    {currentSku && (
                        <ProductImagesView>
                            <ProductImagesView.Thumbs>
                                <ProductImagesView.Thumbs.Carousel
                                    thumbs={currentSku.attr.images?.display}
                                    onSwiper={setThumbsSwiper}
                                    productType={product?.attr.titlePrefix?.value}
                                    productName={product?.title}
                                />
                            </ProductImagesView.Thumbs>
                            <ProductImagesView.Carousel
                                photos={currentSku.attr.images?.display}
                                thumbsSwiper={thumbsSwiper}
                                productType={product?.attr.titlePrefix?.value}
                                productName={product?.title}
                            />
                        </ProductImagesView>
                    )}
                </ProductView.Col>
                <ProductView.Col position="right">
                    <ProductView.Top {...actionsTopProps} />
                    <ProductView.Name>
                        <ProductView.Name.Labels name={product?.title} titlePrefix={product?.attr.titlePrefix?.value}>
                            {product &&
                                product.attr.labels?.value.map((label, index) => {
                                    if (label) {
                                        return (
                                            <ProductCategoryView.Card.Labels.Label key={product.attr.labels?.id[index]}>
                                                {label}
                                            </ProductCategoryView.Card.Labels.Label>
                                        );
                                    }
                                })}
                            {product &&
                                product.attr.marks?.value.map((mark, index) => {
                                    if (mark) {
                                        return (
                                            <ProductCategoryView.Card.Features.Feature
                                                key={product.attr.marks?.id[index]}
                                            >
                                                {mark}
                                            </ProductCategoryView.Card.Features.Feature>
                                        );
                                    }
                                })}
                        </ProductView.Name.Labels>
                        <ProductView.Name.Brand
                            title={brand?.title}
                            image={brand?.image}
                            href={brand ? getMinimalDetailPath(config?.pages.brandDetailUrl || "", brand?.id) : ""}
                        >
                            <EntityFetcher<TechnologySchema, TechnologyEntity>
                                Model={TechnologyEntity}
                                queryKey="techs"
                                idsList={product?.attr.technologies?.id.map((id) => String(id)) || []}
                            >
                                {({ items }) => (
                                    <ProductView.Name.Technologies>
                                        {items.map((tech) => (
                                            <ProductView.Name.Technologies.Technology
                                                key={tech.id}
                                                image={tech.image}
                                                title={tech.title}
                                                href={getMinimalDetailPath(
                                                    config?.pages.technologyDetailUrl || "",
                                                    tech.id
                                                )}
                                            />
                                        ))}
                                    </ProductView.Name.Technologies>
                                )}
                            </EntityFetcher>
                        </ProductView.Name.Brand>
                    </ProductView.Name>
                    {/*Временно убрать по решению руководителя*/}
                    {/*<ProductView.InStockCheckbox isChecked={isChecked} onChange={setIsChecked} />*/}
                    <ProductView.Price
                        normal={currentSku?.salePrice === currentSku?.rrcPrice ? currentSku?.salePrice : null}
                        sale={currentSku?.salePrice !== currentSku?.rrcPrice ? currentSku?.salePrice : null}
                        old={currentSku?.salePrice !== currentSku?.rrcPrice ? currentSku?.rrcPrice : null}
                    />
                    {saleAttrTree &&
                        selectSaleAttrs &&
                        Object.keys(saleAttrTree).map((key) => {
                            if (key === SaleAttr.COLOR) {
                                return (
                                    <ProductView.Colors
                                        key={key}
                                        // items={getSkusWithColorAndQuantity()}
                                        items={isChecked ? getSkusWithColorAndQuantity() : getSkusWithColor()}
                                        setColor={setSelectColorHandler}
                                        activeColor={selectSaleAttrs && getString(selectSaleAttrs[key])}
                                        colorTitle={selectSaleAttrs && getColorNull(getString(selectSaleAttrs[key]))}
                                    />
                                );
                            } else if (saleAttrTree[key].filter((el) => !!el).length) {
                                const allFilteredSkus = getAllSkusWithColorAndQuantity() || []; // Отфильтрованные SKU

                                // Получаем выбранный цвет
                                const selectedColor = selectSaleAttrs?.[SaleAttr.COLOR]?.value[0]; // Предполагаем, что color хранится в этом формате
                                // Логируем, доступен ли размер
                                // console.log(`Получаем выбранный цвет: ${selectedColor} `);

                                // Фильтруем SKU по выбранному цвету
                                const filteredSkus = selectedColor
                                    ? allFilteredSkus.filter((sku) => sku.attr.color?.value[0] === selectedColor)
                                    : allFilteredSkus;

                                // console.log("Все отфильтрованные SKU по выбранному цвету:", filteredSkus);

                                return (
                                    <ProductView.SaleAttr key={key}>
                                        <ProductView.SaleAttr.Label>
                                            <span>{product?.attrNames[key]}:</span>
                                            {selectSaleAttrs && <span>{getString(selectSaleAttrs[key])}</span>}
                                        </ProductView.SaleAttr.Label>
                                        <ProductView.SaleAttr.Row>
                                            <ProductView.SaleAttr.Items>
                                                {saleAttrTree[key]
                                                    .slice()
                                                    .sort((a, b) => {
                                                        const sizeOrder = [
                                                            "XXS",
                                                            "XS",
                                                            "S",
                                                            "M",
                                                            "L",
                                                            "XL",
                                                            "XXL",
                                                            "XXXL",
                                                        ];

                                                        let a_val: string;
                                                        let b_val: string;

                                                        if (Array.isArray(a.value)) {
                                                            a_val = a.value[0];
                                                        } else {
                                                            a_val = a.value;
                                                        }
                                                        if (Array.isArray(b.value)) {
                                                            b_val = b.value[0];
                                                        } else {
                                                            b_val = b.value;
                                                        }

                                                        const sizeAIndex = sizeOrder.indexOf(a_val.toUpperCase());
                                                        const sizeBIndex = sizeOrder.indexOf(b_val.toUpperCase());

                                                        if (sizeAIndex !== -1 && sizeBIndex !== -1) {
                                                            return sizeAIndex - sizeBIndex;
                                                        } else if (sizeAIndex !== -1) {
                                                            return -1;
                                                        } else if (sizeBIndex !== -1) {
                                                            return 1;
                                                        } else {
                                                            const numA = !isNaN(parseFloat(a_val.replace(",", ".")))
                                                                ? parseFloat(a_val.replace(",", "."))
                                                                : a_val;
                                                            const numB = !isNaN(parseFloat(b_val.replace(",", ".")))
                                                                ? parseFloat(b_val.replace(",", "."))
                                                                : b_val;
                                                            if (numA < numB) return -1;
                                                            if (numA > numB) return 1;
                                                            return 0;
                                                        }
                                                    })
                                                    .map((attr) => {
                                                        if (!attr || !attr.id) return null;
                                                        // Проверяем наличие размера
                                                        // const isAvailable = allSkus.some(
                                                        //     (sku) =>
                                                        //         sku.saleAttrs.includes(attr.value[0]) &&
                                                        //         sku.quantity > 0
                                                        // );

                                                        // Логирование проверки наличия размера
                                                        // console.log(`Проверка размера: ${attr.value[0]}`); // Проверяем наличие размера
                                                        const isAvailable = filteredSkus.some((sku) => {
                                                            // Сравниваем атрибут размера напрямую
                                                            const hasSize = sku.attr.size?.value === attr.value[0];

                                                            // Проверяем, что количество больше 0
                                                            const hasQuantity = sku.quantity > 0;

                                                            // Логи для состояния доступности
                                                            // console.log(
                                                            //     `SKU ID: ${sku.id}, Размер: ${
                                                            //         attr.value[0]
                                                            //     }, Количество: ${
                                                            //         sku.quantity
                                                            //     }, Соответствует размеру: ${hasSize}, Доступно ли: ${
                                                            //         hasSize && hasQuantity
                                                            //     }`
                                                            // );

                                                            return hasSize && hasQuantity;
                                                        }); // Логируем, доступен ли размер
                                                        // console.log(`Размер ${attr.value[0]} доступен: ${isAvailable}`);

                                                        if (Array.isArray(attr.id)) {
                                                            return (
                                                                <ProductView.SaleAttr.Text
                                                                    key={attr.id[0]}
                                                                    onChange={() => {
                                                                        setPivot(key);
                                                                        setSelectSaleAttrs((prev) => ({
                                                                            ...prev,
                                                                            [key]: attr,
                                                                        }));
                                                                    }}
                                                                    name={`sale_attr-${attr.id[0]}`}
                                                                    id={`sale_attr-${attr.id[0]}`}
                                                                    checked={
                                                                        selectSaleAttrs &&
                                                                        selectSaleAttrs[key].id[0] === attr.id[0]
                                                                    }
                                                                    // disabled={!isAvailable}
                                                                >
                                                                    {attr.value[0]}
                                                                </ProductView.SaleAttr.Text>
                                                            );
                                                        } else {
                                                            return (
                                                                <ProductView.SaleAttr.Text
                                                                    key={attr.id}
                                                                    onChange={() => {
                                                                        setPivot(key);
                                                                        setSelectSaleAttrs((prev) => ({
                                                                            ...prev,
                                                                            [key]: attr,
                                                                        }));
                                                                    }}
                                                                    name={`sale_attr-${attr.id}`}
                                                                    id={`sale_attr-${attr.id}`}
                                                                    checked={
                                                                        selectSaleAttrs &&
                                                                        selectSaleAttrs[key].id === attr.id
                                                                    }
                                                                    // disabled={!isAvailable}
                                                                >
                                                                    {attr.value}
                                                                </ProductView.SaleAttr.Text>
                                                            );
                                                        }
                                                    })}
                                            </ProductView.SaleAttr.Items>
                                            {key === SaleAttr.SIZE && product?.attr.sizeTable?.value && (
                                                <div
                                                    onClick={() =>
                                                        handleOpenModal("TABLE", {
                                                            html: product.attr.sizeTable?.value,
                                                        })
                                                    }
                                                >
                                                    <ProductView.Sizes.Link href="#sizes">
                                                        Таблица размеров
                                                    </ProductView.Sizes.Link>
                                                </div>
                                            )}
                                        </ProductView.SaleAttr.Row>
                                        {cartError?.size ? (
                                            <ProductView.SaleAttr.Row>
                                                <FormHelperText variant="error">{cartError.size}</FormHelperText>
                                            </ProductView.SaleAttr.Row>
                                        ) : null}
                                    </ProductView.SaleAttr>
                                );
                            }
                        })}
                    <ProductView.Actions {...actionsProps} />
                </ProductView.Col>
            </ProductView.Row>
        </>
    );
};

export default Product;
