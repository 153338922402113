import React from "react";
import pageRegistry from "@/modules/pages/registry";
import { AppProps } from "../App";
import withAuth from "@/modules/hoc/withAuth";
import { RouteComponentProps } from "react-router-dom";
import { useQuery } from "react-query";
import { commonApiService } from "@/api/services";
import usePage from "@/modules/hooks/usePage";

export type PageProps = AppProps & RouteComponentProps;

const UniversalPage: React.FunctionComponent<PageProps> = ({ page, ...other }: PageProps) => {
    const { setPage } = usePage();
    const { location } = other;

    const { pathname } = location;

    const { data, isError } = useQuery(["page", pathname], () => commonApiService.getPage(pathname), {
        // onSuccess: (page) => page && setPage(page),
        onSuccess: (page) => {
            if (page) setPage(page);
        },
        initialData: page,
    });

    if (!data || !data.context.isActive) {
        const Page = pageRegistry.get("404")!;

        return <Page {...page} />;
    }

    if (isError) {
        const Page = pageRegistry.get("500")!;

        return <Page {...page} />;
    }

    const pageType = data.pageModel === "PageShop" ? data.context.pageType : data.pageModel;
    const Page = pageRegistry.get(pageType)!;

    return <Page {...data} />;
};

export default withAuth(UniversalPage);
