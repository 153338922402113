import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Thumbs } from "swiper/core";
import { MODAL_TYPE } from "@/modules/modals"; // Импортируем типы модальных окон
import useModal from "@/modules/hooks/useModal"; // Импортируем хук useModal

SwiperCore.use([Thumbs]);

export type Photo = string;
export type CarouselProps = Partial<{
    thumbsSwiper: Swiper | null;
    photos: Photo[];
    productName: string;
    productType: string;
}>;

const Carousel: React.FunctionComponent<CarouselProps> = ({
    thumbsSwiper,
    photos = [],
    productName = "Продукт",
    productType = "Тип продукта",
}: CarouselProps) => {
    const { handleOpenModal } = useModal(); // Используем хук для открытия модального окна

    const handlePhotoClick = (index: number) => {
        handleOpenModal(MODAL_TYPE.IMAGE, { photos, initialIndex: index }); // Открываем модальное окно с фото
    };

    return (
        <div className="product-photos">
            <Swiper
                thumbs={{
                    swiper: (thumbsSwiper as unknown) as any,
                }}
                breakpoints={{
                    320: {
                        effect: "slide",
                    },
                    568: {
                        effect: "fade",
                    },
                }}
            >
                {photos.map((photo, index) => (
                    <SwiperSlide key={index} className="product-photo">
                        <img
                            className="product-photo__img"
                            src={photo}
                            alt={`${productType} - ${productName} (изображение ${index + 1})`}
                            onClick={() => handlePhotoClick(index)} // Передаем индекс
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default Carousel;
